import React from 'react';
import { Box, Text, Flex, ActionIcon, Image } from '@mantine/core';
import clsx from 'clsx';

import { Content } from 'components/ui/Layout';
import Editor from 'components/forms/EditorJs/Editorjs';
import RussiaIcon from 'public/images/icons/russia.svg';
import UnitedKingdomIcon from 'public/images/icons/uk.svg';
import Preview from './Preview';
import classes from './EditContent.module.css';
import { IObjectData } from 'types';

const plugins = [
  'programs',
  'newYear',
  'blogCard',
  'documents',
  'stars',
  'description',
  'descriptionChatGPT',
  'comments',
  'distances',
  'procedures',
  'feeding_programs',
  'imageObject'
];

type EditContentProps = {
  hotel: any;
  fetchHotel: (lang: string) => void;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  opened: boolean;
  hiddenContent: string;
  handleClickHiddenAction: (name: string) => () => void;
  editorRefRu: React.RefObject<any>;
  shouldUpdateRu: boolean;
  object: IObjectData;
  editorRefEn: React.RefObject<any>;
  shouldUpdateEn: boolean;
};

function EditContent({
  hotel,
  fetchHotel,
  setOpened,
  opened,
  hiddenContent,
  handleClickHiddenAction,
  editorRefRu,
  shouldUpdateRu,
  object,
  editorRefEn,
  shouldUpdateEn
}: EditContentProps) {
  return (
    <Box>
      <Flex align='center' justify='space-between' px='md'>
        <Box />

        <Text fz={24} fw='500'>
          {hotel.name}
        </Text>

        <Flex align='center' gap='6px'>
          <ActionIcon
            variant={hiddenContent === 'en' ? 'filled' : 'transparent'}
            color='var(--mantine-color-green-3)'
            radius='xl'
            onClick={handleClickHiddenAction('en')}
          >
            <Image src={RussiaIcon} />
          </ActionIcon>

          <ActionIcon
            variant={hiddenContent === 'ru' ? 'filled' : 'transparent'}
            color='var(--mantine-color-green-3)'
            radius='xl'
            onClick={handleClickHiddenAction('ru')}
          >
            <Image src={UnitedKingdomIcon} />
          </ActionIcon>

          <ActionIcon
            w='50px'
            variant={!hiddenContent ? 'filled' : 'transparent'}
            color='var(--mantine-color-green-3)'
            radius='xl'
            onClick={handleClickHiddenAction('')}
          >
            <Flex gap='4px'>
              <Image src={RussiaIcon} />
              <Image src={UnitedKingdomIcon} />
            </Flex>
          </ActionIcon>
        </Flex>
      </Flex>

      <Content className={classes.content}>
        {hiddenContent !== 'ru' && (
          <Box className={clsx(classes.editorContainer, hiddenContent === 'en' && classes.editorContainerFull)}>
            <Box className={classes.iconContainer}>
              <Image src={RussiaIcon} />
            </Box>

            <Editor
              editorRef={editorRefRu}
              lang='ru'
              plugins={plugins}
              key={`ru-${shouldUpdateRu}-${hiddenContent}`}
              defaultValue={object.content.ru}
            />
          </Box>
        )}

        {hiddenContent !== 'en' && (
          <Box className={clsx(classes.editorContainer, hiddenContent === 'ru' && classes.editorContainerFull)}>
            <Box className={classes.iconContainer}>
              <Image src={UnitedKingdomIcon} />
            </Box>

            <Editor
              editorRef={editorRefEn}
              lang='en'
              plugins={plugins}
              key={`en-${shouldUpdateEn}-${hiddenContent}`}
              defaultValue={object.content.en}
            />
          </Box>
        )}
      </Content>

      {opened && <Preview onClose={() => setOpened(false)} object={object} hotel={hotel} fetchHotel={fetchHotel} />}
    </Box>
  );
}

export default EditContent;
