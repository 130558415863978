import React, { useEffect } from 'react';
import { Text, Paper, Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { Content } from 'components/ui/Layout';
import RegionSelectForm from 'components/ui/RegionSelectForm/RegionSelectForm';
import TimezonesSelect from 'components/forms/TimezonesSelect';
import { SanatoryFormProps } from '../../types';
import { OSTROVOK_FIELDS } from '../../../AddObject/OstrovokForm/schema';
import InputForm from 'components/ui/InputForm';

type FormProps = {
  hotel: SanatoryFormProps;
};

function OstrovokFormEdit({ hotel }: FormProps) {
  const { t } = useTranslation();
  const { place_name, address, coordinates, timezone, provider_id } = hotel;

  const {
    setValue
  } = useFormContext();

  useEffect(() => {
    if (!hotel) {
      return;
    }
    setValue(OSTROVOK_FIELDS.LOCATION, address?.location);
    setValue(OSTROVOK_FIELDS.PLACE_ID, place_name);
    setValue(`${OSTROVOK_FIELDS.COORDINATES}.${OSTROVOK_FIELDS.LNG}`, coordinates.lng);
    setValue(`${OSTROVOK_FIELDS.COORDINATES}.${OSTROVOK_FIELDS.LAT}`, coordinates.lat);
    setValue(OSTROVOK_FIELDS.TIMEZONE, timezone);
    setValue(OSTROVOK_FIELDS.PROVIDER_ID, provider_id);
  }, [hotel, setValue]);

  return (
    <Content>
      <Paper w='650px' withBorder shadow='md' p={30} radius='md'>
        <Text fz='30px'>{t('Objects.New.Form1.Title')}</Text>

        <Flex direction='column' gap='md'>
          <InputForm
            name={OSTROVOK_FIELDS.LOCATION}
            label={t('Objects.New.Form.Address')}
            placeholder={t('Objects.New.Form.Address.Placeholder')}
            required
          />

          <RegionSelectForm
            required
            defaultName={place_name}
            label={t('Objects.New.Form.SearchArea')}
            placeholder={t('SearchArea.Objects.Placeholder')}
            name={OSTROVOK_FIELDS.PLACE_ID}
          />

          <Flex gap='md'>
            <InputForm
              name={`${OSTROVOK_FIELDS.COORDINATES}.${OSTROVOK_FIELDS.LNG}`}
              label={t('Objects.New.Form.Lng')}
              placeholder={t('Objects.New.Form.Lng.Placeholder')}
              required
            />

            <InputForm
              name={`${OSTROVOK_FIELDS.COORDINATES}.${OSTROVOK_FIELDS.LAT}`}
              label={t('Objects.New.Form.Lat')}
              placeholder={t('Objects.New.Form.Lat.Placeholder')}
              required
            />
            <TimezonesSelect required name={OSTROVOK_FIELDS.TIMEZONE} label={t('Objects.New.Form.Timezone')} />
          </Flex>
        </Flex>
      </Paper>
    </Content>
  );
}

export default OstrovokFormEdit;
