import * as yup from 'yup';
import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';
import _extend from 'lodash/extend';

export const enum OSTROVOK_FIELDS {
  PROVIDER_ID = 'provider_id',
  PROVIDER = 'provider',
  LOCATION = 'location',
  PLACE_ID = 'place_id',
  COORDINATES = 'coordinates',
  LAT = 'lat',
  LNG = 'lng',
  TIMEZONE = 'timezone'
}

export const validationSchema = () =>
  yup.object({
    [OSTROVOK_FIELDS.PROVIDER_ID]: yup.string().required('Обязательное поле'),
    [OSTROVOK_FIELDS.LOCATION]: yup.string().required('Обязательное поле'),
    [OSTROVOK_FIELDS.PLACE_ID]: yup.string().required('Обязательное поле'),
    [OSTROVOK_FIELDS.COORDINATES]: yup.object().shape({
      [OSTROVOK_FIELDS.LAT]: yup
        .string()
        .matches(/^-?([1-8]?[0-9](\.\d+)?|90(\.0+)?)$/, 'Некорректный формат широты. Пример: 45.9999999')
        .required('Обязательное поле'),
      [OSTROVOK_FIELDS.LNG]: yup
        .string()
        .matches(
          /^-?((1[0-7][0-9]|[1-9]?[0-9])(\.\d+)?|180(\.0+)?)$/,
          'Некорректный формат долготы. Пример: 45.9999999'
        )
        .required('Обязательное поле')
    }),
    [OSTROVOK_FIELDS.TIMEZONE]: yup.string().required('Обязательное поле')
  });

export const defaultValues = {
  [OSTROVOK_FIELDS.PROVIDER_ID]: '',
  [OSTROVOK_FIELDS.LOCATION]: '',
  [OSTROVOK_FIELDS.PLACE_ID]: '',
  [OSTROVOK_FIELDS.COORDINATES]: {
    [OSTROVOK_FIELDS.LAT]: '',
    [OSTROVOK_FIELDS.LNG]: ''
  },
  [OSTROVOK_FIELDS.TIMEZONE]: 'Europe/Moscow'
};

export const extendDefaultValues = (values: object | null = {}) => {
  return _extend({}, defaultValues, _omitBy(values, _isNil));
};
