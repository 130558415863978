import React, { useState } from 'react';
import { Box, Text, ActionIcon, Button, Flex } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import cx from 'clsx';
import { IconIndentDecrease, IconMenu2 } from '@tabler/icons-react';

import classes from './SideLeft.module.css';
import { useAppDispatch, useAppSelector } from 'store';
import { loginSelector } from 'store/authSlice/selectors';
import { logoutAction } from 'store/authSlice/actions';
import { removeFromLocalStorage } from 'utils/storage';

type Props = React.PropsWithChildren<{
  className?: string | undefined;
  email: string;
}>;

function SideLeft({ children, className, email }: Props) {
  const [opened, setOpened] = useState<boolean>(true);
  const loginData = useAppSelector(loginSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClick = (flag: boolean) => {
    setOpened(flag);
  };

  const handleLogout = async () => {
    await dispatch(logoutAction());
    removeFromLocalStorage('user');

    navigate('/login');
  };

  return (
    <Box className={cx(className, classes.root)}>
      {opened && (
        <Box className={classes.fullContainer}>
          <Flex w='100%' justify='space-between' align='center' p='lg'>
            <Flex direction='column' gap='xs'>
              <Text c='var(--mantine-color-gray-3)' fw='bold' fz='sm' tt='uppercase'>
                Sanatory.ru
              </Text>
              <Text c='var(--mantine-color-gray-5)' fw='500' fz='12px'>
                {email}
              </Text>
            </Flex>

            <ActionIcon size={36} onClick={() => handleClick(false)} variant='transparent'>
              <IconIndentDecrease color='#fff' size={24} />
            </ActionIcon>
          </Flex>

          <Box className={classes.content}>{children}</Box>
          <Button mt='auto' onClick={handleLogout} loading={loginData.loading} size='lg' color='dark'>
            Выйти
          </Button>
        </Box>
      )}

      {!opened && (
        <Box className={classes.shortContainer} onClick={() => handleClick(true)}>
          <IconMenu2 color='#fff' />
        </Box>
      )}
    </Box>
  );
}

export default SideLeft;
