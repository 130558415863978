import * as yup from 'yup';
import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';
import _extend from 'lodash/extend';

export const enum SANATORY_FIELDS {
  NAME = 'name',
  LOCATION = 'location',
  EMAIL = 'email',
  PHONE = 'phone',
  CURRENCY = 'currency',
  PLACE_ID = 'place_id',
  LEGAL_ENTITY = 'legal_entity',
  COORDINATES = 'coordinates',
  NUMBER = 'number',
  COMMISSION = 'commission_in_percent',
  DATE = 'from_date',
  CONTRACTS = 'contracts',
  SOURCES = 'sources',
  INSURANCE_ID = 'insurance_id',
  INN = 'inn',
  KPP = 'kpp',
  LAT = 'lat',
  LNG = 'lng',
  TIMEZONE = 'timezone'
}

export const validationSchema = () =>
  yup.object({
    [SANATORY_FIELDS.NAME]: yup.string().required('Обязательное поле'),
    [SANATORY_FIELDS.LOCATION]: yup.string().required('Обязательное поле'),
    [SANATORY_FIELDS.EMAIL]: yup.string().email('Введите корректный e-mail').required('Обязательное поле'),
    [SANATORY_FIELDS.PHONE]: yup
      .string()
      .required('Обязательное поле'),
    [SANATORY_FIELDS.LEGAL_ENTITY]: yup.object().shape({
      [SANATORY_FIELDS.INN]: yup.string().nullable().transform((value) => {
        return value === null ? undefined : value;
      }).required('Обязательное поле')
    }),
    [SANATORY_FIELDS.CURRENCY]: yup.string().required('Обязательное поле'),
    [SANATORY_FIELDS.PLACE_ID]: yup.string().required('Обязательное поле'),
    [SANATORY_FIELDS.COORDINATES]: yup.object().shape({
      [SANATORY_FIELDS.LAT]: yup
        .string()
        .matches(/^-?([1-8]?[0-9](\.\d+)?|90(\.0+)?)$/, 'Некорректный формат широты. Пример: 45.9999999')
        .required('Обязательное поле'),
      [SANATORY_FIELDS.LNG]: yup
        .string()
        .matches(
          /^-?((1[0-7][0-9]|[1-9]?[0-9])(\.\d+)?|180(\.0+)?)$/,
          'Некорректный формат долготы. Пример: 45.9999999'
        )
        .required('Обязательное поле')
    }),
    [SANATORY_FIELDS.TIMEZONE]: yup.string().required('Обязательное поле'),
    [SANATORY_FIELDS.SOURCES]: yup.array().of(
      yup.object().shape({
        [SANATORY_FIELDS.CONTRACTS]: yup.array().of(
          yup.object().shape({
            [SANATORY_FIELDS.NUMBER]: yup.string().required('Обязательное поле'),
            [SANATORY_FIELDS.DATE]: yup.string().required('Обязательное поле'),
            [SANATORY_FIELDS.COMMISSION]: yup
              .number()
              .transform((value) => (isNaN(value) ? undefined : value))
              .required('Обязательное поле')
              .min(0, 'Значение должно быть не менее 0')
              .max(100, 'Значение должно быть не более 100')
          })
        )
      })
    )
  });

export const defaultValues = {
  [SANATORY_FIELDS.CURRENCY]: 'RUB',
  [SANATORY_FIELDS.TIMEZONE]: 'Europe/Moscow'
};

export const extendDefaultValues = (values: object | null = {}) => {
  return _extend({}, defaultValues, _omitBy(values, _isNil));
};
