import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Tabs, Menu, Flex, ActionIcon } from '@mantine/core';
import { IconArrowNarrowLeft, IconDots } from '@tabler/icons-react';
import _isNil from 'lodash/isNil';

import defaultValue from './defaultValue.json';

import Loader from 'components/ui/Loader';
import { Main, TopBar } from 'components/ui/Layout';
import { useAppDispatch, useAppSelector } from 'store';
import showErrors from 'utils/showErrors';
import {
  clearObjectOneAction,
  fetchOneObjectAction,
  saveObjectAction,
  updateObjectAction
} from 'store/objectsSlice/actions';
import { objectSelector } from 'store/objectsSlice/selectors';
import { IObjectData, IObjectDataFull, STATUSES } from 'types';
import fromBlocksMapper from 'utils/hotelMapper/fromBlocksMapper';
import { SANATORY, OSTROVOK } from '../AddObject/constants';
import OstrovokProviderModal from './OstrovokProviderForm/OstrovokProviderForm';
import SanatoryProviderModal from './SanatoryProviderForm/SanatoryProviderForm';
import { ContentType } from './types';
import EditContent from './EditContent';
import EditObject from './EditObject';
import Form from 'components/ui/Form';
import { validationSchema as editOstrovokSchema } from '../AddObject/OstrovokForm/schema';
import { validationSchema as editObjectSchema } from '../AddObject/SanatoryForm/schema';
import { toastify } from 'utils/toastify';

function Edit() {
  const dispatch = useAppDispatch();
  const { id }: any = useParams();
  const { t } = useTranslation();
  const editorRefRu = useRef<any>(null);
  const editorRefEn = useRef<any>(null);
  const navigate = useNavigate();
  const [hiddenContent, setHiddenContent] = useState<string>('en');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [shouldUpdateRu, setShouldUpdateRu] = useState<boolean>(false);
  const [shouldUpdateEn, setShouldUpdateEn] = useState<boolean>(false);
  const [opened, setOpened] = useState(false);
  const [objectState, object, hotel] = useAppSelector(objectSelector);

  const fetchHotel = (lang: string): void => {
    dispatch(fetchOneObjectAction({ id, lang }));
  };

  useEffect(() => {
    fetchHotel('ru');

    return () => {
      dispatch(clearObjectOneAction());
    };
  }, []);

  if (!hotel && objectState !== STATUSES.FULFILLED) {
    return (
      <Flex h='100vh' w='100%' align='center'>
        <Loader />
      </Flex>
    );
  }

  const objectWithoutNulls = {
    ...object,
    content: {
      ru: {
        ...object.content.ru,
        blocks: object.content.ru.blocks.filter((block: any) => !_isNil(block))
      },
      en: {
        ...object.content.en,
        blocks: object.content.en.blocks.filter((block: any) => !_isNil(block))
      }
    }
  };

  const getEditorData = async (): Promise<IObjectData> => {
    let savedDataRu = objectWithoutNulls.content.ru || {};
    let savedDataEn = objectWithoutNulls.content.en || {};

    // Always try to save both editors if they exist, regardless of which tab is active
    try {
      if (editorRefRu.current?.save) {
        savedDataRu = await editorRefRu.current.save();
      }
    } catch (error) {
      console.error('Error saving RU editor data:', error);
    }

    try {
      if (editorRefEn.current?.save) {
        savedDataEn = await editorRefEn.current.save();
      }
    } catch (error) {
      console.error('Error saving EN editor data:', error);
    }

    return {
      ...objectWithoutNulls,
      content: {
        ru: savedDataRu,
        en: savedDataEn
      }
    };
  };

  const handleClickHiddenAction = (name: string) => async () => {
    try {
      const data: IObjectData = await getEditorData();
      dispatch(saveObjectAction(data));
      setHiddenContent(name);
    } catch (error) {
      toastify('error', 'Произошла ошибка при переключении языка');
    }
  };

  const handleSubmit = async (formData: any) => {
    const data: IObjectData = await getEditorData();
    const errors = [...showErrors(data.content.ru), ...showErrors(data.content.en)];
    if (errors.length === 0) {
      const object: IObjectDataFull = fromBlocksMapper(data, 'ru');

      const formattedSources = formData.sources?.map((source: any) => ({
        contracts: source.contracts?.filter(Boolean) || [],
        insurance_id: source.insurance_id?.filter(Boolean)?.[0] || null
      })).filter((source: any) => source.contracts.length > 0 && source.insurance_id);

      const objectData = {
        ...formData,
        ...object,
        sources: formattedSources
      };

      try {
        await dispatch(updateObjectAction({ object: objectData, id })).unwrap();
        toastify('success', 'Информация об объекте обновлена');
      } catch (error) {
        toastify('error', 'Возникла ошибка');
      }
    }
  };

  const handleReset = () => {
    navigate('/objects');
  };

  const handleError = (errors: unknown) => {
    console.log('handle error: ', errors);
  };

  const handlePreview = async () => {
    const data: IObjectData = await getEditorData();
    dispatch(saveObjectAction(data));
    setOpened(true);
  };

  const handleTemplate = () => {
    dispatch(
      saveObjectAction({
        ...objectWithoutNulls,
        content: defaultValue.content
      })
    );

    setShouldUpdateEn(!shouldUpdateEn);
    setShouldUpdateRu(!shouldUpdateRu);
  };

  const handleBack = () => {
    navigate('/objects');
  };

  const renderProviderModal = () => {
    switch (hotel.provider) {
      case SANATORY:
        return <OstrovokProviderModal id={hotel.id} setShowModal={setShowModal} showModal={showModal} />;
      case OSTROVOK:
        return <SanatoryProviderModal id={hotel.id} setShowModal={setShowModal} showModal={showModal} />;
    }
  };

  return (
    <Main>
      <Form
        schema={hotel.provider === SANATORY ? editObjectSchema() : editOstrovokSchema()}
        onSubmit={handleSubmit}
        onError={handleError}
        onReset={handleReset}
      >
        <Tabs color='green' defaultValue={ContentType.Content}>
          <TopBar>
            <Flex style={{ cursor: 'pointer' }} align='center' gap='4px' onClick={handleBack}>
              <IconArrowNarrowLeft color='black' />
              {t('Action.ToList')}
            </Flex>

            <Tabs.List>
              <Tabs.Tab fz='20px' value={ContentType.Content}>
                {t('Objects.Tab.Name1')}
              </Tabs.Tab>
              <Tabs.Tab fz='20px' value={ContentType.Object}>
                {t('Objects.Tab.Name2')}
              </Tabs.Tab>
            </Tabs.List>

            <Flex align='center' gap='lg'>
              <Button size='sm' type='submit' color='green'>
                {t('Action.Save')}
              </Button>
              <Menu>
                <Menu.Target>
                  <ActionIcon size='lg' color='gray'>
                    <IconDots />
                  </ActionIcon>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item>
                    <Button fullWidth onClick={() => setShowModal(true)} color='gray'>
                      {t('Action.ChangeProvider')}
                    </Button>
                  </Menu.Item>
                  <Menu.Item>
                    <Button onClick={handleTemplate} disabled={false} color='gray' fullWidth>
                      {t('Common.Template')}
                    </Button>
                  </Menu.Item>
                  <Menu.Item>
                    <Button onClick={handlePreview} fullWidth color='gray'>
                      {t('Action.Preview')}
                    </Button>
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Flex>
          </TopBar>

          <Tabs.Panel value={ContentType.Content}>
            <EditContent
              hotel={hotel}
              fetchHotel={fetchHotel}
              setOpened={setOpened}
              opened={opened}
              hiddenContent={hiddenContent}
              handleClickHiddenAction={handleClickHiddenAction}
              editorRefRu={editorRefRu}
              shouldUpdateRu={shouldUpdateRu}
              object={objectWithoutNulls}
              editorRefEn={editorRefEn}
              shouldUpdateEn={shouldUpdateEn}
            />
          </Tabs.Panel>
          <Tabs.Panel value={ContentType.Object}>
            <EditObject hotel={hotel} />
          </Tabs.Panel>
        </Tabs>
      </Form>
      {renderProviderModal()}
    </Main>
  );
}

export default Edit;
