import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { createSearchParams, useNavigate, Outlet, Link, useSearchParams } from 'react-router-dom';
import { Breadcrumbs, Anchor, Button, Text, Table, Box, Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconEdit } from '@tabler/icons-react';

import { Main, TopBar, Content } from 'components/ui/Layout';

import { catalogFetchAction, fetchSearchAreaAction, fetchSearchAreaMoreAction } from 'store/searchAreaSlice/actions';

import { searchAreaAllSelector } from 'store/searchAreaSlice/selectors';
import { IRegionDocument, ISearchArea, ISearchAreaBreadcrumbs, STATUSES } from 'types';
import Loader from 'components/ui/Loader';
import RegionSelect from 'components/ui/RegionSelect';

type SearchType = Record<string, string>;

const mainBreadcrumb: ISearchAreaBreadcrumbs = {
  name: 'Главная',
  id: 12001
};

const searchType: SearchType = {
  coordinates: 'по координатам',
  area: 'по границам'
};

function SearchArea() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const osmId: any = searchParams.get('osm_id');
  const navigate = useNavigate();
  const [page, setPage] = useState(2);

  const dispatch = useAppDispatch();
  const [searchAreas, breadcrumbs, isLast, stateMore, total]: [
    ISearchArea[],
    ISearchAreaBreadcrumbs[],
    boolean,
    string,
    number
  ] = useAppSelector(searchAreaAllSelector);

  useEffect(() => {
    dispatch(
      fetchSearchAreaAction({
        page: 1,
        parent_osm_id: osmId || undefined
      })
    );

    dispatch(catalogFetchAction());
  }, [osmId]);

  useEffect(() => {
    isLast && navigate(-1);
  }, [isLast]);

  const buildSearchPath = (id?: number) => {
    if (!id) return '';

    return createSearchParams({
      osm_id: String(id)
    }).toString();
  };

  const handleClick = (area: ISearchArea) => () => {
    navigate({
      search: buildSearchPath(area.osm_id)
    });
  };

  const handleEdit = (element: ISearchArea) => (e: any) => {
    e.stopPropagation();

    navigate({
      pathname: `/search_area/${element.id}`,
      search: buildSearchPath(osmId)
    });
  };

  const handleSelect = (regionObj: IRegionDocument) => {
    navigate(`/search_area/${regionObj?.place_id}`);
  };

  const handleScroll = (e: any) => {
    const scrollHeight = e.currentTarget.scrollHeight;
    const scrollTop = e.currentTarget.scrollTop;
    const clientHeight = e.currentTarget.clientHeight;

    if (scrollHeight - (scrollTop + clientHeight) < 200) {
      if (stateMore !== STATUSES.PENDING) {
        setPage(page + 1);
        if (searchAreas.length >= total) {
          return;
        }
        dispatch(
          fetchSearchAreaMoreAction({
            page,
            parent_osm_id: osmId || undefined
          })
        );
      }
    }
  };

  const items = [mainBreadcrumb, ...breadcrumbs].map((item, index) => {
    if (index !== breadcrumbs.length) {
      return (
        <Anchor component={Link} to={{ search: buildSearchPath(item.osm_id) }} key={index}>
          {item.name}
        </Anchor>
      );
    }

    return (
      <Anchor key={index} variant='text'>
        {item.name}
      </Anchor>
    );
  });

  if (!searchAreas.length) {
    return (
      <Flex h='100vh' w='100%' align='center'>
        <Loader />
      </Flex>
    );
  }

  return (
    <Main>
      <TopBar>
        <Flex w='100%' align='center' gap='xl'>
          <Text fz='h1' fw='500'>
            {t('SearchArea.Title')}
          </Text>

          <Box style={{ minInlineSize: '240px' }}>
            <RegionSelect width='auto' placeholder={t('SearchArea.Objects.Placeholder')} onSelect={handleSelect} />
          </Box>
        </Flex>
      </TopBar>

      <Content onScroll={handleScroll}>
        <Flex my='md'>
          <Breadcrumbs>{items}</Breadcrumbs>
        </Flex>

        <Table bg='white' withTableBorder style={{ '--table-border-color': 'var(--mantine-color-gray-4)' }}>
          <Table.Thead bg='var(--sb-main-gray)'>
            <Table.Tr style={{ '--table-border-color': 'var(--mantine-color-gray-3)' }}>
              <Table.Th h='70px'>{t('SearchArea.Table.Field1')}</Table.Th>
              <Table.Th>{t('SearchArea.Table.Field2')}</Table.Th>
              <Table.Th>{t('SearchArea.Table.Field3')}</Table.Th>
              <Table.Th>{t('SearchArea.Table.Field4')}</Table.Th>
              <Table.Th>{t('SearchArea.Table.Field5')}</Table.Th>
              <Table.Th>{t('SearchArea.Table.Field6')}</Table.Th>
              <Table.Th />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {searchAreas.map((element) => (
              <Table.Tr key={element.id} onClick={handleClick(element)}>
                <Table.Td fz={14}>{element.name.ru}</Table.Td>
                <Table.Td fz={14}>{element.country.ru}</Table.Td>
                <Table.Td fz={14}>{searchType[element.search_mode]}</Table.Td>
                <Table.Td fz={14}>{element.is_searchable ? 'да' : 'нет'}</Table.Td>
                <Table.Td fz={14}>{element.center.coordinates.map((c) => c.toFixed(2)).join(', ')}</Table.Td>
                <Table.Td fz={14}>{String(element.search_radius)}</Table.Td>
                <Table.Td fz={14}>
                  <Button onClick={handleEdit(element)} color='gray' size='xs'>
                    Редактировать
                  </Button>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
        {stateMore === STATUSES.PENDING && <Loader />}
      </Content>

      <Outlet />
    </Main>
  );
}

export default SearchArea;
